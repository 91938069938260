import pageService from "src/services/page.service";
import { IconSquareRoundedX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import slugify from "react-slugify";
import { toast } from "react-toastify";

const PagesModal = ({ selectedPage = null, onClose }) => {
  const [inputFields, setInputFields] = useState({
    title: "",
    slug: "",
    content: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateValues(inputFields);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (selectedPage !== null) {
        pageService
          .updatePage(
            selectedPage.id,
            inputFields.title,
            inputFields.slug,
            inputFields.content
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Page modifiée avec succès");
              onClose();
            } else {
              toast.error("Erreur lors de la modification de la page");
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(
                "Cette URL de page existe déjà pour votre compte, veuillez en choisir une autre."
              );
            } else {
              toast.error("Erreur lors de la création de la page");
            }
          });
      } else {
        pageService
          .createPage(inputFields.title, inputFields.slug, inputFields.content)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Page créée avec succès");
              onClose();
            } else {
              toast.error("Erreur lors de la création de la page");
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(
                "Cette URL de page existe déjà pour votre compte, veuillez en choisir une autre."
              );
            } else {
              toast.error("Erreur lors de la création de la page");
              console.log(error);
            }
          });
      }
    }
  };

  const handleSuglify = (e) => {
    setInputFields({ ...inputFields, slug: slugify(e.target.value) });
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.title) {
      errors.title = "Le nom de la page est requis";
    }
    if (!inputValues.slug) {
      errors.slug = "L'URL de la page est requise";
    }
    return errors;
  };

  useEffect(() => {
    if (selectedPage !== null) {
      setInputFields({
        title: selectedPage.title,
        slug: selectedPage.slug,
        content: selectedPage.content,
      });
    }
  }, [selectedPage]);

  return (
    <div className="fixed inset-0 h-screen w-screen flex justify-center items-center bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="bg-white rounded-xl shadow-sm w-full max-w-xs md:max-w-lg lg:max-w-xl 2xl:max-w-5xl p-4 sm:p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="font-medium text-lg">
            {selectedPage !== null ? "Modifier la page" : "Créer une page"}
          </h1>
          <button onClick={onClose}>
            <IconSquareRoundedX size={25} />
          </button>
        </div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nom de la page
            </label>
            <input
              id="title"
              type="text"
              name="title"
              value={inputFields["title"]}
              onChange={handleChange}
              onKeyUp={handleSuglify}
              placeholder="Mes musiques du moment"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title}</p>
            )}
          </div>

          <div className="flex gap-1 flex-col">
            <label
              htmlFor="slug"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              URL d'accès
            </label>
            <input
              id="slug"
              name="slug"
              type="text"
              value={inputFields["slug"]}
              onChange={handleChange}
              autoComplete="on"
              readOnly
              placeholder="mes-musiques-du-moment"
              className="block w-full rounded-md border-0 py-1.5 px-3 cursor-not-allowed text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.slug && (
              <p className="text-red-500 text-xs">{errors.slug}</p>
            )}
          </div>

          <div className="flex gap-1 flex-col col-span-1 sm:col-span-2">
            <label
              htmlFor="content"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Légende (message d'accueil)
            </label>
            <textarea
              name="content"
              id="content"
              cols="30"
              rows="4"
              value={inputFields["content"]}
              onChange={handleChange}
              placeholder="Mes musiques du moment, mes coups de coeur, mes découvertes..."
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            ></textarea>
            {errors.content && (
              <p className="text-red-500 text-xs">{errors.content}</p>
            )}
          </div>

          <div className="flex col-span-1 sm:col-span-2 gap-4">
            <button className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 w-full">
              {selectedPage !== null ? "Modifier la page" : "Créer la page"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { PagesModal };
