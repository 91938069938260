import React from 'react';
import IconBento from '../assets/img/icon_black.png';



const Logo = ({ className }) => {
  return (
    <img
      className={className}
      src={IconBento}
      alt="BentoPop Icon"
    />
  );
};

export default Logo;
