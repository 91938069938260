import { toast } from "react-toastify";

const { default: BentoPopAPI } = require("./caller.service");

const authService = {
  login(email, password) {
    return BentoPopAPI.post("/login", { email, password });
  },

  register(
    first_name,
    last_name,
    username,
    email,
    password,
    password_confirmation
  ) {
    return BentoPopAPI.post("/register", {
      first_name,
      last_name,
      username,
      email,
      password,
      password_confirmation,
    });
  },

  hasToken() {
    const token = sessionStorage.getItem("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  },

  checkToken() {
    return BentoPopAPI.post("/checkToken");
  },

  async logout() {
    await BentoPopAPI.post("/logout");
    sessionStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success("Déconnexion réussie");
  },
};

export default authService;
