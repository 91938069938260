import homeService from "src/services/home.service";
import React, { useEffect, useState } from "react";

const ThirdSection = () => {
  const [stats, setStats] = useState([
    {
      links: 0,
      pages: 0,
      users: 0,
    },
  ]);

  useEffect(() => {
    homeService
      .getStats()
      .then((response) => {
        if (response.status === 200) {
          setStats(response.data.stats);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setStats]);

  return (
    <>
      <div className="bg-zinc-900 relative py-20 sm:py-32 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base leading-7 text-orange-600">
                Utilisateurs actifs
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-slate-200 sm:text-5xl">
                {stats.users}
              </dd>
            </div>
            <div className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base leading-7 text-orange-600">
                Pages créées
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-slate-200 sm:text-5xl">
                {stats.pages}
              </dd>
            </div>
            <div className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base leading-7 text-orange-600">
                Liens créés
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-slate-200 sm:text-5xl">
                {stats.links}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ThirdSection;
