import axios from "axios";
import authService from "./auth.service";

const BentoPopAPI = axios.create({
  baseURL: "https://api.bentopop.me/api",
  headers: {
    "Content-type": "application/json",
  },
});

BentoPopAPI.interceptors.request.use(
  (config) => {
    if (authService.hasToken()) {
      config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
        "token"
      )}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default BentoPopAPI;
