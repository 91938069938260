import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../components/Logo";
import authService from "../services/auth.service";
import { toast } from "react-toastify";

const Login = () => {
  const [inputFields, setInputFields] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.email) {
      errors.email = "L'adresse e-mail est requise";
    }
    if (inputValues.email && !/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "L'adresse e-mail est invalide";
    }
    if (!inputValues.password) {
      errors.password = "Le mot de passe est requis";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateValues(inputFields);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      await authService
        .login(inputFields.email, inputFields.password)
        .then((response) => {
          if (response.status === 200 && response.data.token) {
            sessionStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            toast.success("Connexion réussie");
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          toast.error(
            "Erreur de connexion, veuillez vérifier vos identifiants"
          );
          console.log(error);
        });
    }
  };

  useEffect(() => {
    document.title = "BentoPop - Connexion";
  }, []);

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-gradient-to-r from-orange-600 via-orange-300 to-orange-200">
      <div className="bg-slate-200 rounded-md shadow-md w-full max-w-sm md:max-w-lg 2xl:max-w-3xl flex flex-col items-center p-4 sm:p-10">
        <Logo className="w-3/5 mb-6" />
        <form className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Adresse e-mail
            </label>
            <input
              id="email"
              type="text"
              name="email"
              value={inputFields["email"]}
              onChange={handleChange}
              placeholder="john.doe@gmail.com"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 text-sm leading-6"
            />
            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Mot de passe
            </label>
            <input
              id="password"
              name="password"
              type="password"
              value={inputFields["password"]}
              onChange={handleChange}
              autoComplete="on"
              placeholder="Password"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 text-sm leading-6"
            />
            {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
          </div>

          <div className="md:col-span-2 text-center">
            <button className="bg-orange-600 text-white shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 w-full disabled:pointer-events-none disabled:opacity-50">
              Se connecter
            </button>
          </div>
        </form>

        <div className="flex justify-center items-center space-x-2 mt-5">
          <p className="text-sm text-slate-500">Pas encore de compte?</p>
          <Link
            to="/register"
            className="text-primary text-sm font-medium text-orange-600"
          >
            S'enregistrer
          </Link>
        </div>
      </div>
    </div>
  );
};

export { Login };
