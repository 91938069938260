import React from 'react';
import LogoBento from '../assets/img/logo_black.png';



const Logo = ({ className }) => {
  return (
    <img
      className={className}
      src={LogoBento}
      alt="BentoPop Logo"
    />
  );
};

export default Logo;
