import { Link, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoIcon from "../components/LogoIcon";
import authService from "../services/auth.service";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  // Fonction de déconnexion
  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      authService
        .checkToken()
        .then((response) => {
          if (response.status !== 200) {
            navigate("/login");
            sessionStorage.removeItem("token");
            localStorage.removeItem("user");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            navigate("/login");
            sessionStorage.removeItem("token");
            localStorage.removeItem("user");
          }
        });
    } else {
      if (localStorage.getItem("user") !== null) {
        localStorage.removeItem("user");
      }
      navigate("/login");
    }
    setIsCheckingToken(false);
    document.title = "BentoPop - Tableau de bord";
  }, [setIsCheckingToken, navigate]);

  if (isCheckingToken) {
    return <div>Chargement...</div>;
  }
  // Layout du dashboard
  return (
    <div className="w-full bg-slate-300 min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">
      <div className="w-full sm:w-3/4 lg:w-2/3 flex flex-col justify-between">
        <div>
          <div className="bg-slate-200 rounded-xl shadow-sm w-full p-4 sm:p-6 flex flex-col sm:flex-row mt-10 items-center justify-between">
            <LogoIcon className="w-10 sm:w-14" />
            <div className="flex gap-4 sm:gap-8 mt-4 sm:mt-0">
              <li className="list-none">
                <Link to={"/dashboard"} className="font-medium text-gray-800">
                  Mes pages
                </Link>
              </li>
              <li className="list-none">
                <Link to={"/account"} className="font-medium text-gray-800">
                  Mon compte
                </Link>
              </li>
              <li
                className="list-none cursor-pointer font-bold text-orange-600 hover:text-orange-800"
                onClick={handleLogout}
              >
                Déconnexion
              </li>
            </div>
          </div>
          <Outlet />
        </div>
        <div className="top-[100vh] mt-5">
          <div className="bg-slate-200 p-4 rounded-t-xl flex flex-col sm:flex-row justify-between items-center">
            <LogoIcon className="w-8" />
            <div className="flex text-gray-800 mt-4 sm:mt-0">
              <p>BentoPop - Développé par Gaspard & Geoffrey </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardLayout };
