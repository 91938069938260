import { useEffect, useState } from "react";
import accountService from "../services/account.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();

  const [inputFields, setInputFields] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleDestroy = async (e) => {
    accountService
      .deleteAccount()
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("user");
          sessionStorage.removeItem("token");
          navigate("/home");
        } else {
          toast.error("Une erreur est survenue");
        }
      })
      .catch((error) => {
        toast.error("Une erreur est survenue");
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateValues(inputFields);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      accountService
        .updateInfos(
          inputFields.first_name,
          inputFields.last_name,
          inputFields.username,
          inputFields.email,
          inputFields.password,
          inputFields.password_confirmation
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Vos informations ont été mises à jour");
            localStorage.setItem("user", JSON.stringify(response.data.user));
          } else {
            toast.error("Une erreur est survenue");
          }
        })
        .catch((error) => {
          toast.error("Une erreur est survenue");
        });
    }
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.first_name) {
      errors.first_name = "Le prénom est requis";
    }
    if (!inputValues.last_name) {
      errors.last_name = "Le nom de famille est requis";
    }
    if (!inputValues.username) {
      errors.username = "Le nom d'utilisateur est requis";
    }
    if (!inputValues.email) {
      errors.email = "L'adresse e-mail est requise";
    }
    if (inputValues.email && !/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "L'adresse e-mail est invalide";
    }
    if (inputValues.password && inputValues.password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères";
    }
    if (inputValues.password !== inputValues.password_confirmation) {
      errors.password_confirmation = "Les mots de passe ne correspondent pas";
    }
    return errors;
  };

  useEffect(() => {
    accountService
      .getInfos()
      .then((response) => {
        if (response.status === 200) {
          setInputFields(response.data.user);
        } else {
          toast.error("Une erreur est survenue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="bg-slate-200 rounded-xl shadow-sm w-full h-full p-4 flex flex-col sm:flex-row mt-10 items-center justify-between">
        <h1 className="font-medium text-gray-800">
          Votre compte BentoPop
          <span className="bg-orange-600 bg-opacity-20 rounded py-1 px-2 ml-3 font-medium text-gray-800">
            @{inputFields.username}
          </span>
        </h1>
      </div>
      <div className="bg-slate-200 rounded-xl shadow-sm mt-4 p-4">
        <h2 className="font-medium text-gray-800">Vos informations</h2>
        <form
          className="grid grid-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mt-4"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Prénom
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={inputFields.first_name}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.first_name ? (
              <p className="text-red-500 text-xs">{errors.first_name}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nom de famille
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={inputFields.last_name}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.last_name ? (
              <p className="text-red-500 text-xs">{errors.last_name}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nom d'utilisateur
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={inputFields.username}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.username ? (
              <p className="text-red-500 text-xs">{errors.username}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Adresse e-mail
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={inputFields.email}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.email ? (
              <p className="text-red-500 text-xs">{errors.email}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nouveau mot de passe
            </label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.password ? (
              <p className="text-red-500 text-xs">{errors.password}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="password_confirmation"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Répéter le nouveau mot de passe
            </label>
            <input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
            {errors.password_confirmation ? (
              <p className="text-red-500 text-xs">
                {errors.password_confirmation}
              </p>
            ) : null}
          </div>
          <div className="flex gap-4">
            <button className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 w-full">
              Envoyer les nouvelles informations
            </button>
          </div>
        </form>
      </div>
      <div className="bg-slate-200 rounded-xl shadow-sm mt-4 p-4">
        <h2 className="font-medium text-red-800">Supprimer votre compte</h2>
        <p className="text-red-800 mt-4">
          Vous souhaitez supprimer votre compte ? Vos pages et leurs liens
          seront supprimés. Cette action est irréversible.
        </p>
        <div className="flex gap-4 mt-4">
          <button
            onClick={handleDestroy}
            className="bg-red-600 text-primary-foreground shadow hover:bg-red-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          >
            Supprimer mon compte
          </button>
        </div>
      </div>
    </div>
  );
};

export { Account };
