const { default: BentoPopAPI } = require("./caller.service");

const accountService = {
  getInfos() {
    return BentoPopAPI.get("/account");
  },

  updateInfos(
    first_name,
    last_name,
    username,
    email,
    password,
    password_confirmation
  ) {
    return BentoPopAPI.put("/account/update", {
      first_name,
      last_name,
      username,
      email,
      password,
      password_confirmation,
    });
  },

  deleteAccount() {
    return BentoPopAPI.delete("/account/delete");
  },
};

export default accountService;
