import axios from "axios";

const pokemonAPI = axios.create({
  baseURL: "https://pokeapi.co/api/v2",
  headers: {
    "Content-Type": "application/json",
  },
});

const pokemonService = {
  async search(query) {
    return pokemonAPI.get(`/pokemon/${query}`);
  },

  async getDetails(id) {
    return pokemonAPI.get(`/pokemon/${id}`);
  },
};

export default pokemonService;
