import React from "react";
import { IconId, IconShare, IconArchive, IconFriends, IconLink, IconAccessible } from "@tabler/icons-react";



const SecondSection = () => {

  return (
    <>
      <div className="w-full flex justify-center items-center bg-zinc-900 relative pt-[25%] md:pt-[35%] lg:pt-[40%] xl:pt-[42%] 2xl:pt-[45%]">
        <div className="w-4/5 h-auto">
          <h2 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-base text-white text-center">
            <span className="text-orange-600 font-bold">Bento Pop</span> : Votre Univers Pop Culturel Personnalisé
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mt-20">
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 transition-all ease-in-out duration-300">
              <IconId size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-xl md:text-2xl font-bold tracking-tight text-slate-200">Personnalisation</h3>
              <p className="font-normal text-slate-200 dark:text-gray-400">Dans le vaste océan de la pop culture, <span className="font-bold">Bento Pop </span>offre un îlot personnel où vos choix et vos goûts définissent le paysage. Chaque utilisateur crée un espace unique qui reflète sa personnalité et ses préférences, sans les contraintes des algorithmes généralistes.</p>
            </div>
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 transition-all ease-in-out duration-300">
              <IconShare size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-xl md:text-2xl font-bold tracking-tight text-slate-200">Partage et Découverte Facilités</h3>
              <p className="font-normal text-slate-200 dark:text-gray-400">En rendant les collections facilement partageables, <span className="font-bold">Bento Pop </span> encourage la découverte et l'échange culturel entre amis, famille, et au-delà. C'est un moyen puissant de découvrir de nouvelles inspirations à travers les yeux de ceux qui partagent vos intérêts.</p>
            </div>
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 transition-all ease-in-out duration-300">
              <IconArchive size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-xl md:text-2xl font-bold tracking-tight text-slate-200">Une Archive Vivante de Vos Passions </h3>
              <p className="font-normal text-slate-200 dark:text-gray-400">Au fil du temps, votre page devient une archive dynamique de vos évolutions et découvertes culturelles. C'est un journal intime de vos passions, accessible à tout moment pour revisiter vos coups de cœur ou partager des recommandations.</p>
            </div>
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 transition-all ease-in-out duration-300">
              <IconAccessible size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-xl md:text-2xl font-bold tracking-tight text-slate-200">Simplicité et Accessibilité</h3>
              <p className="font-normal text-slate-200 dark:text-gray-400"><span className="font-bold">Bento Pop </span> démocratise la création de contenu en rendant le processus de curation aussi simple que quelques clics. Pas besoin de connaissances techniques ou de design ; l'application est conçue pour être accessible à tous, mettant la puissance de la curation entre les mains de chacun.</p>
            </div>
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 transition-all ease-in-out duration-300">
              <IconLink size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-xl md:text-2xl font-bold tracking-tight text-slate-200">Un Pont Entre les Mondes Culturels</h3>
              <p className="font-normal text-slate-200 dark:text-gray-400">En couvrant une gamme étendue de médias - de la musique au cinéma, en passant par la littérature - <span className="font-bold">Bento Pop </span> crée des ponts entre différents domaines de la pop culture, favorisant une appréciation plus riche et plus intégrée de l'art sous toutes ses formes.</p>
            </div>
            <div className="block max-w-sm mx-auto p-6 border border-slate-200 rounded-lg shadow-lg hover:bg-orange-600 transition-all ease-in-out duration-300">
              <IconFriends size={48} stroke={1.5} color="#FFFFFF" />
              <h3 className="mb-2 mt-3 text-2xl font-bold tracking-tight text-slate-200 dark:text-white">Engagement Communautaire Amplifié</h3>
              <p className="font-normal text-slate-200 dark:text-gray-400"> C'est un lieu d'échange culturel actif, où chaque page peut devenir le point de départ de discussions approfondies, de découvertes mutuelles, et d'une appréciation partagée pour la richesse infinie de la pop culture.</p>
            </div>
          </div>
        </div>
      </div>
    </>)



}

export default SecondSection;