import { IconInfoCircle, IconSquareRoundedX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { SearchResultCard } from "./SearchResultCard";
import { toast } from "react-toastify";

import linkService from "src/services/link.service";
import spotifyService from "src/services/external/spotify.service";
import tmdbService from "src/services/external/tmdb.service";
import dbzService from "src/services/external/dbz.service";
import pokemonService from "src/services/external/pokemon.service";

const LinkModal = ({ selectedLink = null, pageId, onClose }) => {
  const [inputFields, setInputFields] = useState({
    type: "spotify",
    size: 1,
    content: "",
    selectedName: "",
  });

  const [errors, setErrors] = useState({});
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    if (e.target.name === "type") {
      setInputFields({
        ...inputFields,
        content: "",
        selectedName: "",
        type: e.target.value,
      });
      setSearchResults([]);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      if (inputFields.type === "spotify") {
        spotifyService
          .search(e.target.value)
          .then((response) => {
            if (response.status === 200) {
              setSearchResults(response.data.tracks.items);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (inputFields.type === "tmdb") {
        tmdbService
          .search(e.target.value)
          .then((response) => {
            if (response.status === 200) {
              let results = response.data.results;
              results = results.filter(
                (result) => result.media_type !== "person"
              );
              setSearchResults(results.slice(0, 4));
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (inputFields.type === "dbz") {
        dbzService
          .search(e.target.value)
          .then((response) => {
            if (response.status === 200) {
              setSearchResults(response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (inputFields.type === "pokemon") {
        pokemonService
          .search(e.target.value)
          .then((response) => {
            if (response.status === 200) {
              let new_response = [
                {
                  id: response.data.id,
                  name: response.data.name,
                  sprites: response.data.sprites,
                },
              ];
              setSearchResults(new_response);
            }
          })
          .catch((error) => {
            if (error.response.status !== 404) console.error(error);
          });
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateValues(inputFields);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (selectedLink !== null) {
        linkService
          .updateLink(pageId, selectedLink.id, inputFields.size)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Lien modifié avec succès");
              onClose();
            } else {
              console.log(response);
              toast.error("Erreur lors de la modification du lien");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Erreur lors de la modification du lien");
          });
      } else {
        linkService
          .createLink(
            pageId,
            inputFields.content,
            inputFields.type,
            inputFields.size
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Lien créé avec succès");
              onClose();
            } else {
              console.log(response);
              toast.error("Erreur lors de la création du lien");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Erreur lors de la création du lien");
          });
      }
    }
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.type) {
      errors.type = "Le type est requis";
    }
    if (!inputValues.size) {
      errors.size = "La taille est requise";
    }
    if (!inputValues.content) {
      errors.content = "Le contenu est requis";
    }
    return errors;
  };

  useEffect(() => {
    if (selectedLink !== null) {
      setInputFields({
        type: selectedLink.type,
        size: selectedLink.size,
        content: selectedLink.content,
      });
    }
  }, [selectedLink]);

  return (
    <div className="fixed inset-0 h-screen w-screen flex justify-center items-center bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="bg-white rounded-xl shadow-sm w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:max-w-5xl p-4 sm:p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="font-medium text-lg">
            {selectedLink !== null ? "Modifier le lien" : "Créer un lien"}
          </h1>
          <button onClick={onClose}>
            <IconSquareRoundedX size={25} />
          </button>
        </div>
        <form className="grid grid-cols-2 gap-4 w-full" onSubmit={handleSubmit}>
          <div className="flex gap-1 flex-col">
            <label
              htmlFor="type"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Type
            </label>
            <select
              name="type"
              id="type"
              value={inputFields["type"]}
              onChange={handleChange}
              disabled={selectedLink !== null}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            >
              <option value="spotify">Spotify</option>
              <option value="tmdb">Série ou film</option>
              <option value="dbz">Dragon Ball Z</option>
              <option value="pokemon">Pokémon</option>
            </select>
            {errors.type ? (
              <p className="text-red-500 text-xs">{errors.type}</p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label
              htmlFor="size"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Taille
            </label>
            <select
              name="size"
              id="size"
              value={inputFields["size"]}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            >
              <option value="1">Petit</option>
              <option value="2">Grand</option>
            </select>
            {errors.size ? (
              <p className="text-red-500 text-xs">{errors.size}</p>
            ) : null}
          </div>
          <div className="flex gap-1 flex-col col-span-2">
            <label
              htmlFor="search"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Recherche
            </label>
            <input
              type="text"
              name="search"
              onChange={handleSearch}
              disabled={selectedLink !== null}
              placeholder="AC/DC, Hunger Games, ..."
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
          </div>
          {searchResults.length > 0 && (
            <div className="col-span-2 grid grid-cols-4 gap-2">
              {searchResults.map((result) => (
                <SearchResultCard
                  key={result.id}
                  item={result}
                  type={inputFields["type"]}
                  handleSelect={(id, name) => {
                    setInputFields({
                      ...inputFields,
                      content: id,
                      selectedName: name,
                    });
                  }}
                />
              ))}
            </div>
          )}
          {inputFields["selectedName"] && (
            <p className="col-span-2 text-gray-800 text-sm font-medium">
              Vous avez sélectionné : {inputFields["selectedName"]}
            </p>
          )}
          {inputFields["type"] === "pokemon" && (
            <p className="col-span-2 text-orange-500 text-sm font-medium flex items-center gap-1">
              <IconInfoCircle size={15} /> Pour les pokémon, veuillez taper le
              nom anglais exact.
            </p>
          )}
          {errors.content ? (
            <p className="text-red-500 text-xs">{errors.content}</p>
          ) : null}
          <div className="flex col-span-2 gap-4">
            <button className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 w-full">
              {selectedLink !== null ? "Modifier le lien" : "Créer le lien"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { LinkModal };
