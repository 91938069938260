import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {

  return (
    <div className="bg-slate200 dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Démarrez Votre Voyage Culturel Aujourd'hui !</h2>
          <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Rejoignez l'élite de la pop culture avec Bento Pop : une expérience sur mesure où vos passions deviennent votre signature. Inscrivez-vous aujourd'hui et transcendez le quotidien.</p>
          <Link to="/register" className="inline-flex items-center bg-orange-600 text-white shadow hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 px-6 py-2 text-xs md:text-sm lg:text-base xl:text-xl rounded-md font-medium transition-colors disabled:pointer-events-none disabled:opacity-50">Rejoignez-nous !</Link>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;