const { default: BentoPopAPI } = require("./caller.service");

const pageService = {
  getPages() {
    return BentoPopAPI.get("/pages");
  },

  updatePage(id, title, slug, content) {
    return BentoPopAPI.put(`/pages/${id}`, { title, slug, content });
  },

  createPage(title, slug, content) {
    return BentoPopAPI.post("/pages", { title, slug, content });
  },

  destroyPage(id) {
    return BentoPopAPI.delete(`/pages/${id}`);
  },
};

export default pageService;
