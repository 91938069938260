const { default: BentoPopAPI } = require("./caller.service");

const linkService = {
  getLinks(pageId) {
    return BentoPopAPI.get(`/pages/${pageId}/links`);
  },

  updateLink(pageId, id, size) {
    return BentoPopAPI.put(`/pages/${pageId}/links/${id}`, {
      size,
    });
  },

  createLink(pageId, content, type, size) {
    return BentoPopAPI.post(`/pages/${pageId}/links`, { content, type, size });
  },

  destroyLink(pageId, id) {
    return BentoPopAPI.delete(`/pages/${pageId}/links/${id}`);
  },
};

export default linkService;
