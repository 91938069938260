import { useEffect, useState } from "react";

const SearchResultCard = ({ item, type, handleSelect }) => {
  const [resultData, setResultData] = useState([
    {
      id: null,
      title: "",
      subtitle: "",
      image: "",
      type: "",
    },
  ]);

  useEffect(() => {
    if (type === "spotify") {
      setResultData({
        id: item.id,
        title: item.name,
        subtitle: item.artists.map((artist) => artist.name).join(", "),
        image: item.album.images[0].url,
        type: type,
      });
    } else if (type === "tmdb") {
      if (item.poster_path === null) {
        item.poster_path = "/nA2I8fU2p6FwzQjZ9j4v4YRu9vR.jpg";
      }
      if (item.media_type === "tv") {
        setResultData({
          id: item.media_type + ":" + item.id,
          title: item.name,
          subtitle: "Série | " + item.first_air_date,
          image: "https://image.tmdb.org/t/p/w500" + item.poster_path,
          type: type,
        });
      } else {
        setResultData({
          id: item.media_type + ":" + item.id,
          title: item.title,
          subtitle: "Film | " + item.release_date,
          image: "https://image.tmdb.org/t/p/w500" + item.poster_path,
          type: type,
        });
      }
    } else if (type === "dbz") {
      setResultData({
        id: item.id,
        title: item.name,
        subtitle: "Personnage",
        image: item.image,
        type: type,
      });
    } else if (type === "pokemon") {
      setResultData({
        id: item.id,
        title: item.name,
        subtitle: "Pokémon",
        image: item.sprites.front_default,
        type: type,
      });
    }
  }, [item, type]);

  return (
    <div
      className="bg-slate-200 rounded-xl shadow-sm cursor-pointer hover:bg-slate-300 transition-colors"
      onClick={() =>
        handleSelect(
          resultData["id"],
          resultData["title"] + " - " + resultData["subtitle"]
        )
      }
    >
      <img
        src={resultData["image"]}
        alt={`${resultData["title"]} - ${resultData["subtitle"]}`}
        className={`w-full h-auto object-cover rounded-t-xl ${type === "dbz" ? "p-4" : ""}`}
      />
      <div className="p-4">
        <p className="truncate font-medium">{resultData["title"]}</p>
        <p className="truncate text-sm font-medium text-gray-600">{resultData["subtitle"]}</p>
      </div>
    </div>

  );
};

export { SearchResultCard };
