import { useEffect, useState } from "react";
import pageService from "../services/page.service";
import { toast } from "react-toastify";
import { IconClick, IconEdit, IconLink, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { PagesModal } from "../components/pages/PagesModal";

const Dashboard = () => {
  const [pages, setPages] = useState([]);

  const getPages = () => {
    pageService
      .getPages()
      .then((response) => {
        if (response.status === 200) {
          setPages(response.data.pages);
        } else {
          toast.error("Erreur lors de la récupération des pages");
        }
      })
      .catch((error) => {
        if (error.response.status !== 404)
          toast.error("Erreur lors de la récupération des pages");
      });
  };

  const handlePageDestroy = (id) => {
    pageService
      .destroyPage(id)
      .then((response) => {
        if (response.status === 200) {
          setPages(pages.filter((page) => page.id !== id));
          toast.success("Page supprimée avec succès");
          getPages();
        } else {
          toast.error("Erreur lors de la suppression de la page");
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression de la page");
      });
  };

  useEffect(() => {
    getPages();
  }, []);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editPage, setEditPage] = useState(null);

  const handleOpenModal = (page) => {
    setEditPage(page);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditPage(null);
    getPages();
  };

  const user = JSON.parse(localStorage.getItem("user")); // Convertir la chaîne JSON en objet
  return (
    <div>
      <div className="bg-slate-200 rounded-xl shadow-sm w-full h-full p-4 flex flex-row mt-10 items-center justify-between">
        <h1 className="font-medium text-gray-800">
          Vos pages BentoPop
          <span className="bg-orange-600 bg-opacity-20 rounded p-1 ml-1 font-medium text-gray-800">
            {pages.length}
          </span>
        </h1>
        <button
          className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none"
          onClick={() => handleOpenModal(null)}
        >
          Créer une nouvelle page
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {pages.map((page) => (
          <div
            key={page.id}
            className="bg-slate-200 rounded-xl p-4 shadow-sm flex justify-between flex-col gap-2"
          >
            <div className="flex flex-row gap-2 justify-between items-center">
              <h2 className="col-span-3 font-medium text-gray-800">
                {page.title}
              </h2>
              <div className="flex flex-row gap-4 bg-orange-600 p-2 rounded-full shadow-sm">
                <button
                  className="text-white"
                  onClick={() => handleOpenModal(page)}
                >
                  <IconEdit size={15} />
                </button>
                <Link to={`/dashboard/${page.id}/links`} className="text-white">
                  <IconLink size={15} />
                </Link>
                <button
                  className="text-white"
                  onClick={() => handlePageDestroy(page.id)}
                >
                  <IconTrash size={15} />
                </button>
              </div>
            </div>
            <Link
              to={`/bento/${user.username}/${page.slug}`}
              className="font-light flex gap-2 items-center text-gray-800 hover:text-orange-600 transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
            >
              Voir le bento sur BentoPop.me
              <IconClick size={15} />
            </Link>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <PagesModal onClose={handleCloseModal} selectedPage={editPage} />
      )}
    </div>
  );
};

export { Dashboard };
