import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import linkService from "../services/link.service";
import { LinkCard } from "../components/links/LinkCard";
import { LinkModal } from "../components/links/LinkModal";


const PageLinks = () => {
  const { pageId } = useParams();
  const [links, setLinks] = useState([]);

  const getLinks = () => {
    linkService
      .getLinks(pageId)
      .then((response) => {
        if (response.status === 200) {
          setLinks(response.data.links);
        } else {
          toast.error("Erreur lors de la récupération des pages");
        }
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          setLinks([]);
          toast.error("Erreur lors de la récupération des pages");
        }
        console.error(error);
      });
  };

  const handleLinkDestroy = (id) => {
    linkService
      .destroyLink(pageId, id)
      .then((response) => {
        if (response.status === 200) {
          setLinks(links.filter((link) => link.id !== id));
          toast.success("Lien supprimée avec succès");
          getLinks();
        } else {
          toast.error("Erreur lors de la suppression du lien");
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la suppression du lien");
      });
  };

  useEffect(() => {
    getLinks();
  }, [pageId]);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editLink, setEditLink] = useState(null);

  const handleOpenModal = (link) => {
    setEditLink(link);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditLink(null);
    getLinks();
  };

  return (
    <div>
      <div className="rounded-xl shadow-sm w-full bg-slate-200 p-4 flex flex-col md:flex-row mt-10 items-center justify-between">
        <h1 className="font-medium text-gray-800 mb-2 md:mb-0">Liens de votre page</h1>
        <div className="flex gap-3">
          <Link
            to="/dashboard"
            className="bg-gray-400 text-primary-foreground shadow hover:bg-gray-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none"
          >
            Retour aux pages
          </Link>
          <button
            className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 h-9 px-4 py-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none"
            onClick={() => handleOpenModal(null)}
          >
            Créer un nouveau lien
          </button>
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 sm:gap-4 sm:mt-4 auto-rows-[100px] 2xl:auto-rows-[192px] xl:auto-rows-[120px] lg:auto-rows-[110px]] mb-5">
        {links &&
          links.map((link) => (
            <LinkCard
              key={link.id}
              link={link}
              editable={true}
              handleDestroy={handleLinkDestroy}
              handleEdit={() => handleOpenModal(link)}
            />
          ))}
      </div>


      {isModalOpen && (
        <LinkModal
          onClose={handleCloseModal}
          selectedLink={editLink}
          pageId={pageId}
        />
      )}
    </div>
  );
};

export { PageLinks };
