import React from "react";
import { Link } from "react-router-dom";
import { IconRocket } from "@tabler/icons-react";
import ScreenDashboard from "../ScreenDashboard";


const FirstSection = () => {

  return (
    <>
      <div className="w-full h-screen flex flex-col justify-center items-center bg-gradient-to-tr from-orange-600 via-zinc-900 to-zinc-900 rela">
        <div className="w-4/5">
          <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold text-white text-center">
            Immortalisez votre empreinte dans <span className="font-bold">la pop culture</span> : une page à la fois.
          </h1>
        </div>
        <div className="w-1/2 text-center mt-5">
          <p className="text-md lg:text-lg xl:text-xl text-slate-200">
            Découvrez l'univers derrière vos inspirations avec Bento Pop :
            partagez, explorez et connectez autour des passions culturelles qui
            nous rassemblent.
          </p>
        </div>
        <div className="flex items-center mt-10">
          <Link
            to="/register"
            className="inline-flex items-center bg-orange-600 text-white shadow hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 px-6 py-2 text-xs md:text-sm lg:text-base xl:text-xl rounded-md font-medium transition-colors disabled:pointer-events-none disabled:opacity-50"
          >
            <IconRocket
              size={24}
              stroke={1.5}
              color="#FFFFFF"
              className="mr-2"
            />
            Commencer
          </Link>
        </div>
      </div>
      <div className="w-full h-48 flex justify-center items-center bg-zinc-900 relative">
        <ScreenDashboard className="screen-dashboard-fade w-4/5 rounded-lg absolute shadow-xl border-zinc-800 border z-40 top-[-20%]" />
      </div>
    </>
  );
}

export default FirstSection;


