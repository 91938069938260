import React from 'react';
import LogoLetterWhite from '../assets/img/Icone_blanc.png';



const LogoLetterW = ({ className }) => {
  return (
    <img
      className={className}
      src={LogoLetterWhite}
      alt="Your Company"
    />
  );
};

export default LogoLetterW;