import React from "react";
import Header from "../components/HomePage/HeaderHome";
import FirstSection from "../components/HomePage/FirstSection";
import SecondSection from "../components/HomePage/SecondSection";
import ThirdSection from "../components/HomePage/ThirdSection";
import CallToAction from "../components/HomePage/CallToAction";
import FooterHome from "../components/HomePage/FooterHome";
import { EasterEgg } from "../components/EasterEgg";

export const Home = () => {
  return (
    <div className="w-full h-screen">
      <Header />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <CallToAction />
      <FooterHome />
      <EasterEgg />
    </div>
  );
};

export default Home;
