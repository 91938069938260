import { useEffect, useState } from "react";
import LogoSpotify from "../logos/LogoSpotify";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import tmdbService from "src/services/external/tmdb.service";
import LogoTMDB from "../logos/LogoTMDB";
import LogoDBZ from "../logos/LogoDBZ";
import dbzService from "src/services/external/dbz.service";
import LogoPokemon from "../logos/LogoPokemon";
import pokemonService from "src/services/external/pokemon.service";

const {
  default: spotifyService,
} = require("src/services/external/spotify.service");

const LinkCard = ({ link, editable = null, handleDestroy, handleEdit }) => {
  const [linkData, setLinkData] = useState([
    {
      name: "",
      info1: "",
      info2: "",
      image: "",
    },
  ]);

  const truncate = (str) => {
    return str.length > 20 ? str.slice(0, 20) + "..." : str;
  };

  useEffect(() => {
    if (link.type === "spotify") {
      spotifyService
        .getTrack(link.content)
        .then((response) => {
          const artists = response.data.artists
            .map((artist) => artist.name)
            .join(", ");
          setLinkData({
            name: truncate(response.data.name),
            info1: truncate(artists),
            info2: truncate(response.data.album.name),
            image: response.data.album.images[0].url,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (link.type === "tmdb") {
      tmdbService
        .getDetails(link.content)
        .then((response) => {
          setLinkData({
            name: response.data.title || response.data.name,
            info1: response.data.release_date || response.data.first_air_date,
            info2: response.data.first_air_date ? "Série" : "Film",
            image: `https://image.tmdb.org/t/p/w500${response.data.poster_path}`,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (link.type === "dbz") {
      dbzService.getDetails(link.content).then((response) => {
        setLinkData({
          name: response.data.name,
          info1: "Planète: " + response.data.originPlanet.name,
          info2: "Personnage",
          image: response.data.image,
        });
      });
    } else if (link.type === "pokemon") {
      pokemonService.getDetails(link.content).then((response) => {
        console.log(response);
        setLinkData({
          name:
            response.data.name.charAt(0).toUpperCase() +
            response.data.name.slice(1),
          info1: response.data.abilities
            .map(
              (ability) =>
                ability.ability.name.charAt(0).toUpperCase() +
                ability.ability.name.slice(1)
            )
            .join(", ")
            .replace("-", " "),
          info2: "Personnage",
          image: response.data.sprites.front_default,
        });
      });
    }
  }, [link.content, link.type]);

  return (
    <div
      className={`rounded-xl md-ml-0 shadow-sm md:col-span-${link.size} w-full max-w-md lg:max-w-full flex flex-col mt-4 lg:mt-0 lg:flex-row lg:items-center bg-slate-200 relative group`}
    >
      <img
        src={linkData.image}
        alt="cover"
        className={`h-full rounded-xl ${link.type === "dbz" ? "p-4" : ""}`}
      />
      <div className="flex flex-col gap-1 p-6">
        <h2 className="text-sm xl:text-md 2xl:text-lg font-semibold text-gray-800">{linkData.name}</h2>
        <p className="text-sm 2xl:text-md font-normal text-gray-800">{linkData.info1}</p>
        <p className="text-sm 2xl:text-md font-normal text-gray-800">{linkData.info2}</p>
      </div>
      <div className="absolute top-4 right-4">
        {link.type === "spotify" ? (
          <LogoSpotify className="w-8 h-8" />
        ) : link.type === "tmdb" ? (
          <LogoTMDB className="h-3" />
        ) : link.type === "pokemon" ? (
          <LogoPokemon className="h-8" />
        ) : (
          <LogoDBZ className="h-5" />
        )}
      </div>
      {editable && (
        <div className="hidden absolute group-hover:flex w-full h-full justify-center items-center bg-gray-900 bg-opacity-40 rounded-xl">
          <div className="flex gap-4">
            <button className="text-white" onClick={() => handleEdit(link)}>
              <IconEdit size={25} />
            </button>
            <button
              className="text-white"
              onClick={() => handleDestroy(link.id)}
            >
              <IconTrash size={25} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export { LinkCard };
