import React from 'react';
import ScreenDashboard from '../assets/img/screen_dashboard.png';


const ScreenDashboardMain = ({ className }) => {
  return (
    <img
      className={className}
      src={ScreenDashboard}
      alt="BentoPop Dashboard"
    />
  );
}

export default ScreenDashboardMain;