import axios from "axios";

const dbzAPI = axios.create({
  baseURL: "https://dragonball-api.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const dbzService = {
  async search(query) {
    return dbzAPI.get(`/characters?name=${query}`);
  },

  async getDetails(id) {
    return dbzAPI.get(`/characters/${id}`);
  },
};

export default dbzService;
