import axios from "axios";
import qs from "qs";

const spotifyAPI = axios.create({
  baseURL: "https://api.spotify.com/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

const spotifyService = {
  async getAccessToken() {
    const tokenExpiration = sessionStorage.getItem("spotify_token_expiration");
    const accessToken = sessionStorage.getItem("spotify_access_token");

    if (!accessToken || Date.now() > tokenExpiration) {
      return this.requestAccessToken().then((response) => {
        const { access_token, expires_in } = response.data;
        sessionStorage.setItem("spotify_access_token", access_token);
        sessionStorage.setItem(
          "spotify_token_expiration",
          Date.now() + expires_in * 1000 // expires_in is in seconds, convert to milliseconds
        );
        return access_token;
      });
    } else {
      return Promise.resolve(accessToken);
    }
  },

  async requestAccessToken() {
    const auth_token = btoa(
      process.env.REACT_APP_SPOTIFY_CLIENT_ID +
        ":" +
        process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
    );
    const data = qs.stringify({ grant_type: "client_credentials" });
    return axios.post("https://accounts.spotify.com/api/token", data, {
      headers: {
        Authorization: `Basic ${auth_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

  async getTrack(id) {
    return this.getAccessToken().then((accessToken) => {
      return spotifyAPI.get(`/tracks/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    });
  },

  async search(query) {
    return this.getAccessToken().then((accessToken) => {
      return spotifyAPI.get(`/search?q=${query}&type=track&limit=4`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    });
  },
};

export default spotifyService;
