import axios from "axios";

const tmdbAPI = axios.create({
  baseURL: "https://api.themoviedb.org/3",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
  },
});

const tmdbService = {
  async search(query) {
    return tmdbAPI.get(`/search/multi?query=${query}&page=1`);
  },

  async getDetails(id) {
    const [tmdb_type, tmdb_id] = id.split(":");
    if (tmdb_type === "movie") {
      return tmdbAPI.get(`/movie/${tmdb_id}`);
    } else {
      return tmdbAPI.get(`/tv/${tmdb_id}`);
    }
  },
};

export default tmdbService;
