import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import bentoService from "../services/bento.service";
import LogoIcon from "../components/LogoIcon";
import { toast } from "react-toastify";
import { LinkCard } from "../components/links/LinkCard";

const PublicBento = () => {
  const { username, slug } = useParams();
  const [bentoData, setBentoData] = useState({});
  const [bentoLinks, setBentoLinks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    bentoService
      .getBento(username, slug)
      .then((response) => {
        if (response.status === 200) {
          setBentoData(response.data.page);
          setBentoLinks(response.data.page.links);
          document.title = "BentoPop - @" + username;
        } else {
          toast.error("Erreur lors de la récupération de la page");
          navigate("/home");
        }
      })
      .catch((error) => {
        toast.error("Cette page n'existe pas.");
        navigate("/home");
      });
  }, [username, slug]);

  useEffect(() => {
    document.title =
      "BentoPop - " + bentoData["title"] + " (@" + username + ")";
  }, [bentoData, username]);

  return (
    <div className=" w-full min-h-screen bg-gradient-to-r from-orange-400 to-amber-400 flex justify-center">
      <div className="w-3/5 mt-10">
        <div className="bg-slate-200 h-fit p-6 rounded-2xl flex justify-between items-center shadow-md">
          <Link to="/home">
            <LogoIcon className="w-20" />
          </Link>
          <div className="flex flex-col items-end">
            <h1 className="text-4xl font-semibold text-gray-800">
              {bentoData["title"]}
            </h1>
            <h2 className="font-normal text-gray-800">
              {bentoData["content"]}
            </h2>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 sm:gap-4 sm:mt-4 auto-rows-[100px] 2xl:auto-rows-[192px] xl:auto-rows-[120px] lg:auto-rows-[110px]] mb-5">
          {bentoLinks.length > 0
            ? bentoLinks.map((link) => <LinkCard key={link.id} link={link} />)
            : ""}
        </div>
        <div className="sticky top-[100vh]">
          <div className="bg-slate-200 p-4 rounded-t-xl flex justify-between">
            <LogoIcon className="w-8" />
            <div className="flex text-gray-800">
              <p>BentoPop - Développé par Gaspard & Geoffrey </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PublicBento };
