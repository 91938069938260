import React from "react";


const Footer = () => {

  return (
    <>
      <div className="bg-zinc-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="flex flex-col items-center justify-between md:flex-row">
            <p className="text-sm text-gray-400 dark:text-gray-500">© 2024 Bento Pop. Tous droits réservés.</p>
            <p className="mt-4 text-sm text-gray-400 dark:text-gray-500 md:mt-0">Conçu avec par Gaspard & Geoffrey</p>
          </div>
        </div>
      </div>
    </>

  );
}

export default Footer;
