import React from "react";
import { Link } from "react-router-dom";
import LogoLetterW from "../LogoLetter";

const Header = () => {
  return (
    <div className="w-full flex justify-between items-center h-20 bg-zinc-900 z-50 pr-4 md:pr-16 lg:pr-24 xl:pr-32 pl-4 md:pl-16 lg:pl-24 xl:pl-32 pt-6 md:pt-8 lg:pt-10 xl:pt-12">
      <LogoLetterW className="h-20 lg:h-24 xl:h-28" />
      <div>
        <Link
          to="/login"
          className="bg-orange-600 text-primary-foreground shadow hover:bg-orange-300 px-8 py-2  rounded-md text-xs md:text-sm lg:text-base xl:text-xl font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
        >
          Se connecter
        </Link>
      </div>
    </div>
  );
}

export default Header;

