import { useState, useEffect } from "react";

const EasterEgg = () => {
  const [keySequence, setKeySequence] = useState([]);
  const easterEggSequence = ["m", "i", "k", "e"];
  const [showEasterEgg, setShowEasterEgg] = useState(false);

  useEffect(() => {
    const keydownHandler = (event) => {
      setKeySequence((prevSequence) => [...prevSequence, event.key]);
    };

    window.addEventListener("keydown", keydownHandler);

    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, []);

  useEffect(() => {
    if (keySequence.length > 0) {
      const lastKeyTime = Date.now();

      const timerId = setTimeout(() => {
        setKeySequence([]);
      }, 5000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [keySequence]);

  useEffect(() => {
    if (keySequence.join("") === easterEggSequence.join("")) {
      setShowEasterEgg(true);
      setKeySequence([]);
    }
  }, [keySequence]);

  return (
    <div>
      {showEasterEgg ? (
        <div className="fixed inset-0 z-50 h-screen w-screen flex justify-center items-center bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="bg-white rounded-xl shadow-sm w-2/5 cursor-pointer">
            <img
              src="https://64.media.tumblr.com/c547f52118385b2822035221f69feaa4/tumblr_nblat95t1v1rb2l1co1_500.gif"
              alt=""
              className="w-full rounded-xl"
              onClick={() => setShowEasterEgg(false)}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export { EasterEgg };
